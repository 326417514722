import type { FC } from 'react'
import { memo } from 'react'

import { setTildaPageId } from '@redux/features/modals/modals.slice'
import { useAppDispatch } from '@redux/hooks'

import Button from '@base/buttons/Button'
import tildaPagesIds from '@constants/tilda/tildaPagesId'

export type TermsAndConditionsButtonsProps = {
  privacyLabel: string
  termsLabel: string
}

const TermsAndConditionsButtons: FC<TermsAndConditionsButtonsProps> = ({ privacyLabel, termsLabel }) => {
  const dispatch = useAppDispatch()

  const openTerms = () => {
    dispatch(setTildaPageId(tildaPagesIds.terms))
  }

  const openPrivacy = () => {
    dispatch(setTildaPageId(tildaPagesIds.privacy))
  }

  return (
    <>
      <Button onClick={openTerms} type="link">
        {termsLabel}
      </Button>
      <Button onClick={openPrivacy} type="link">
        {privacyLabel}
      </Button>
    </>
  )
}

export default memo(TermsAndConditionsButtons)
